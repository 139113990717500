import React from 'react'
import styled from 'styled-components'
import { OutboundLink } from 'gatsby-plugin-google-gtag'

const Footer = () => {
  return (
    <StyledFooter>
      <span className='copyright'>Copyright &copy; Hutson Inc. All Rights Reserved.</span>
      <span>
        <OutboundLink href='https://www.hutsoninc.com/contact/'>Contact Us</OutboundLink>
      </span>
    </StyledFooter>
  )
}

const StyledFooter = styled.footer`
  background-color: var(--color-n900);
  color: #fff;
  display: flex;
  font-size: 0.875rem;
  justify-content: space-between;
  padding: var(--size-s);

  .copyright {
    color: rgba(255, 255, 255, 0.65);
  }

  a {
    color: #fff;
    display: inline-block;
    text-decoration: none;

    :hover,
    :focus {
      text-decoration: underline;
    }
  }

  @media screen and (max-width: 899px) {
    flex-direction: column-reverse;
    padding: var(--size-m) var(--size-s);

    a {
      font-size: 1.125rem;
      margin-bottom: var(--size-xs);
    }
  }
`

export default Footer
