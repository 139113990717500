import React from 'react'
import styled from 'styled-components'

const Content = ({ children, ...props }) => {
  return <StyledContent {...props}>{children}</StyledContent>
}

const StyledContent = styled.div`
  padding: var(--size-xl);

  @media screen and (min-width: 480px) and (max-width: 899px) {
    padding: var(--size-l) var(--size-m);
  }

  @media screen and (max-width: 479px) {
    padding: var(--size-m) var(--size-s);
  }
`

export default Content
