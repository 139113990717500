import React from 'react'
import * as PropTypes from 'prop-types'
import styled from 'styled-components'
import SEO from './seo'
import Header from './header'
import Footer from './footer'
import { global as GlobalStyles } from 'styles'

const Layout = ({ children, seo, title }) => {
  return (
    <>
      <GlobalStyles />
      <SEO {...seo} />
      <SkipToContent href='#main'>Skip to content</SkipToContent>
      <PageBody>
        <Header title={title} />
        <main id='main' tabIndex='-1'>
          {children}
        </main>
        <Footer />
      </PageBody>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

const SkipToContent = styled.a`
  background: var(--color-n900);
  color: #fff;
  font-size: 1rem;
  height: 1px;
  left: -1000px;
  overflow: hidden;
  padding: var(--size-s);
  position: absolute;
  text-decoration: none;
  top: -1000px;
  width: 1px;

  :active,
  :focus,
  :hover {
    height: auto;
    left: 10px;
    overflow: visible;
    top: 10px;
    width: auto;
    z-index: 9999;
  }
`

const PageBody = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;

  main {
    flex-grow: 2;
    height: 100%;
    outline: none;
    overflow-y: hidden;
  }

  @media screen and (max-width: 899px) {
    display: block;
    height: auto;

    main {
      overflow-y: visible;
    }
  }
`

export default Layout
