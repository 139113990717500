import React, { memo } from 'react'
import styled from 'styled-components'
import { graphql, Link, useStaticQuery } from 'gatsby'
import Img from 'gatsby-image'

const Header = props => {
  const { title = 'Build Your Own' } = props
  const { logoImage } = useStaticQuery(query)
  return (
    <StyledHeader id='header'>
      <Link className='logo-link' to='/'>
        <Img className='logo' alt='Hutson Inc' fluid={logoImage.childImageSharp.fluid} />
      </Link>
      <h1>{title}</h1>
    </StyledHeader>
  )
}

const StyledHeader = styled.header`
  background-color: #fff;
  border-bottom: 1px solid var(--color-n30);
  padding: var(--size-xs) var(--size-s);
  position: relative;
  width: 100%;

  .logo-link {
    display: inline-block;
  }

  .logo {
    width: 180px;
  }

  h1 {
    display: inline-block;
    font-size: var(--font-size-h2);
    font-weight: 700;
    position: absolute;
    right: var(--size-s);
    top: 50%;
    transform: translateY(-50%);
  }

  @media screen and (max-width: 899px) {
    .logo {
      width: 125px;
    }

    h1 {
      font-size: var(--font-size-h5);
    }
  }

  @media print {
    border-bottom: none;
    padding: 0;
    margin-bottom: var(--size-m);

    h1 {
      display: none;
    }
  }
`

const query = graphql`
  {
    logoImage: file(relativePath: { eq: "logo-tag.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 180, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

const arePropsEqual = (prevProps, nextProps) => {
  return prevProps.title === nextProps.title
}

export default memo(Header, arePropsEqual)
