import React from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
import { useLocation } from '@reach/router'
import { useStaticQuery, graphql } from 'gatsby'

const SEO = props => {
  const { pathname } = useLocation()
  const {
    site: {
      siteMetadata: { defaultTitle, twitterUsername },
      siteMetadata,
    },
  } = useStaticQuery(query)

  const {
    description,
    image: imagePath,
    siteUrl,
    title,
  } = {
    ...siteMetadata,
    ...props,
  }

  const pageTitle = title ? `${title} | ${defaultTitle}` : defaultTitle
  const url = `${siteUrl}${pathname}`
  const image = `${siteUrl}${imagePath}`

  return (
    <Helmet title={pageTitle}>
      <meta name='description' content={description} />
      <meta name='image' content={image} />

      <meta property='og:url' content={url} />
      <meta property='og:title' content={pageTitle} />
      <meta property='og:description' content={description} />
      <meta property='og:image' content={image} />

      <meta name='twitter:card' content='summary' />
      <meta name='twitter:site' content={twitterUsername} />
      <meta name='twitter:title' content={pageTitle} />
      <meta name='twitter:description' content={description} />
      <meta name='twitter:image' content={image} />
    </Helmet>
  )
}

SEO.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
}

const query = graphql`
  {
    site {
      siteMetadata {
        defaultTitle: title
        description
        image
        siteUrl
        twitterUsername
      }
    }
  }
`

export default SEO
